import React from "react";
import {graphql, Link} from "gatsby";
import 'swiper/css';
import "swiper/css/navigation";

import Head from "../components/Head";
import Energierechner from "../components/Energierechner";


export const query = graphql`
    {
  allSanityStartseite {
    edges {
      node {
        title
        video
        section {
          title
          text
          marqueetext
          links
          image {
            asset {
              gatsbyImageData(width: 1920)
            }
          }
          color
          btntext
          btnslug
        }
        image {
          asset {
            gatsbyImageData
          }
        }
        logos{
            asset{
            gatsbyImageData
            }
        }
        images{
            asset{
                gatsbyImageData
            }
        }
        sectionlarge{
            sectiontext1
            sectiontext2
            sectionimage{
            asset{ gatsbyImageData }
            }
            video
        }
       
        energierechnermarquee
        ansprechpartner {
          image {
            asset {
              gatsbyImageData
            }
          }
          jobbezeichnung
          title
        }
        blogartikel {
          text
          btnslug
          btntext
          title
        }
        
        testimonialmarquee
        testimonials{
            title
            ort
            text
            image{ asset { gatsbyImageData}}
        }
      }
    }
  }
  allSanitySettings {
    edges {
      node {
        instalink
        fblink
        linkedinlink
      }
    }
  }
}
`


const IndexPage = ({data}) => {

    const page = data.allSanityStartseite.edges[0].node;

    return (
        <div>
            <nav className="energierechner__nav">
                <div className="nav__right">
                    <div className="nav__logo__container">
                        <img src={`../eniosol_logo_desktop_orange.svg`} alt="Eniosol Logo"/>
                    </div>
                </div>
            </nav>
            <Head title={"Energierechner"}/>
            <div className={"energierechner__page"}>
                <Energierechner></Energierechner>
            </div>
        </div>
    )
}

export default IndexPage;
