import React, {useEffect, useRef} from "react";
import "../styles/global.scss";
import {Link} from "gatsby";
import styled from "styled-components";
import BlogTag from "./BlogTag";
import {GatsbyImage, getImage} from "gatsby-plugin-image"
import axios from "axios";
import addToMailchimp from 'gatsby-plugin-mailchimp'
import ReCAPTCHA from "react-google-recaptcha";


const Energierechner = ({}) => {
    const recaptchaRef = useRef(null);  // Ref for the reCAPTCHA component

    const [value, setValue] = React.useState({})


    let serverResponse = useRef();

    function handleChange(e) {
        value[e.target.id] = e.target.value;
        serverResponse.current.style.color = "#000000";
        serverResponse.current.innerText = "";
        setValue({...value})
    }

    async function onSubmit(e) {
        e.preventDefault()

        if (value.abrechnung && value.email && value.strasse && value.nr && value.wohnort && value.plz && value.telefonnummer && value.dachflaeche && value.dachneigung && value.stromverbrauch && value?.captcha) {

            if (document.querySelector('#dsgvo').checked) {
                document.querySelector('#dsgvocircle').style.borderColor = '#000000';

                document.querySelector('.result__container').style.display = "block";

                let result = Math.trunc((value.dachflaeche / 5 * 1000 - (value.stromverbrauch * 0.7)) * 0.08 + value.abrechnung * 0.7);

                document.querySelector('#result').innerText = result;

                console.log('Starting Mailchimp')
                addToMailchimp(value.email)
                    .then(data => {
                        console.log('Mailchimp Success:')
                        console.log(data)
                    })
                    .catch(() => {
                        console.error('Error adding to Mailchimp')
                    })
                let select = document.querySelector('#dachausrichtung')
                value.dachausrichtung = select.options[select.selectedIndex].text;

                let anschrift = value.strasse + " " + value.nr + ", " + value.plz + " " + value.wohnort;
                console.log(anschrift);

                var params = new URLSearchParams();
                params.append('abrechnung', value.abrechnung);
                params.append('email', value.email);
                params.append('anschrift', anschrift);
                params.append('telefonnummer', value.telefonnummer);
                params.append('dachflaeche', value.dachflaeche);
                params.append('dachneigung', value.dachneigung);
                params.append('stromverbrauch', value.stromverbrauch);
                params.append('dachausrichtung', value.dachausrichtung);
                params.append('captcha', value.captcha);

                axios.post('https://api.eniosol.de/energierechner.php', params)
                    .then(function (response) {
                        console.log(response);
                        setValue({});
                        recaptchaRef.current.reset();
                    })
                    .catch(function (error) {
                        console.log(error);
                    });

            } else {
                serverResponse.current.innerText = "Bitte bestätige die Verwendung der Daten!";
                serverResponse.current.style.color = "#F05353";
                document.querySelector('#dsgvocircle').style.borderColor = '#F05353';
            }
        } else {
            serverResponse.current.innerText = "Bitte fülle alle Pflichtfelder aus!";
            serverResponse.current.style.color = "#F05353";

            console.log('didnt work');
        }
    }

    function onChange(value) {
        console.log("Captcha value:", value);
        setValue(prev => ({...prev, captcha: value}));
    }

    //6LcPpdopAAAAADz8mGcyFnn2h_CycnunSnFxRZiU

    return (
        <div className="energierechner__container" id={"energierechner"}>
            <div className="left">
                <div className="energierechner__textcontainer">
                    <p className="large bold">Energierechner</p>
                    <p className="large">
                        Zahlen Sie zuviel Strom? <br/>
                        Wir berechnen, wieviele
                        Euro Sie mit einer
                        Photovoltaikanlage
                        jährlich sparen können.
                    </p>
                    <div className="result__container">
                        <p className="large bold color-orange nomargin">
                            Ergebnis
                        </p>
                        <p className="large color-orange">
                            Sie könnten jährlich bis zu <span id="result"></span>€ sparen.
                        </p>
                    </div>
                </div>
                <div className="acceptancecontainer">
                    <label className="cbcontainer">
                        <input type="checkbox" id="dsgvo"/>
                        <span className="checkmark hover-moveup" id="dsgvocircle"></span>
                    </label>
                    <p className="smalltext energierechner__dsgvo">
                        Ich bin damit einverstanden, dass meine Daten für
                        firmeninterne Zwecke und den Newsletterversand
                        gespeichert werden. Alle Daten werden
                        gemäß <Link to={"/datenschutz"}>Datenschutzbestimmungen</Link> behandelt und nicht an
                        Dritte weitergegeben.
                    </p>
                    <div className="mt-4">
                        <ReCAPTCHA
                            ref={recaptchaRef}
                            sitekey="6Ldwp9opAAAAAPAsgGJyL1lQJ25b4KQ6TXHJv4lE"
                            onChange={onChange}
                        />
                    </div>
                </div>

            </div>
            <div className="right">
                <form className="kontakt__form energie__kontakt__form" onSubmit={onSubmit} method="post" action="/api/energierechner.php">
                    <label className="kontakt__label">
                        <p className="small bold">IHR JÄHRICHER <span className="wordspacing">STROMVERBRAUCH*</span></p>
                        <input type="number" name="stromverbrauch" id="stromverbrauch" className="kontakt__inputfield" placeholder="EINHEITEN kWh UND OHNE NACHKOMMASTELLEN" value={value[`stromverbrauch`] || ``} onChange={handleChange}/>
                    </label>
                    <label className="kontakt__label">
                        <p className="small bold">IHRE LETZTE <span className="wordspacing">JAHRESABRECHNUNG*</span></p>
                        <input type="number" name="abrechnung" id="abrechnung" className="kontakt__inputfield" placeholder="00,00 EURO" value={value[`abrechnung`] || ``} onChange={handleChange}/>
                    </label>
                    <label className="kontakt__label">
                        <p className="small bold"><span className="wordspacing">QUADRATMETER </span>IHRER DACHFLÄCHE*
                        </p>
                        <input type="number" name="dachflaeche" id="dachflaeche" className="kontakt__inputfield" placeholder="00 M²" value={value[`dachflaeche`] || ``} onChange={handleChange}/>
                    </label>
                    <label className="kontakt__label">
                        <p className="small bold">DACHNEIGUNG*</p>
                        <input type="number" name="dachneigung" id="dachneigung" className="kontakt__inputfield" placeholder="00°" value={value[`dachneigung`] || ``} onChange={handleChange}/>
                    </label>
                    <label className="kontakt__label">
                        <p className="small bold"><span className="wordspacing">AUSRICHTUNG </span>IHRES DACHS*</p>
                        <div className="select__wrapper">
                            <select name="dachausrichtung" id="dachausrichtung" className="kontakt__inputfield selectinput">
                                <option value="south">Süden</option>
                                <option value="east">Osten</option>
                                <option value="west">Westen</option>
                                <option value="north">Norden</option>
                                <option value="south-south-east">Süd-Süd-Osten</option>
                                <option value="south-south-west">Süd-Süd-Westen</option>
                                <option value="east-east-north">Ost-Ost-Norden</option>
                                <option value="east-east-south">Ost-Ost-Süden</option>
                                <option value="west-west-north">West-West-Norden</option>
                                <option value="west-west-south">West-West-Süden</option>
                                <option value="north-north-east">Nord-Nord-Osten</option>
                                <option value="north-north-west">Nord-Nord-Westen</option>
                            </select>
                        </div>
                    </label>
                    <label className="kontakt__label anschrift__label">
                        <p className="small bold wordspacing">
                            <span className="desktop">IHRE ANSCHRIFT*</span>
                        </p>
                        <div className={"kontakt__inputfieldcontainer"}>
                            <input type="text" name="strasse" id="strasse" className="kontakt__inputfield" placeholder="STRASSE" value={value[`strasse`] || ``} onChange={handleChange}/>
                            <input type="text" name="nr" id="nr" className="kontakt__inputfield" placeholder="NR" value={value[`nr`] || ``} onChange={handleChange}/>
                        </div>
                    </label>
                    <label className="kontakt__label">
                        <p className="small bold wordspacing">
                            <span className="mobile">IHRE ANSCHRIFT*</span></p>
                        <div className={"kontakt__inputfieldcontainer"}>
                            <input type="text" name="plz" id="plz" className="kontakt__inputfield" placeholder="PLZ" value={value[`plz`] || ``} onChange={handleChange}/>
                            <input type="text" name="wohnort" id="wohnort" className="kontakt__inputfield" placeholder="WOHNORT" value={value[`wohnort`] || ``} onChange={handleChange}/>
                        </div>
                    </label>
                    <label className="kontakt__label">
                        <p className="small bold wordspacing">IHRE TELEFONNUMMER*</p>
                        <input type="tel" name="telefonnummer" id="telefonnummer" className="kontakt__inputfield" placeholder="Telefonnummer" value={value[`telefonnummer`] || ``} onChange={handleChange}/>
                    </label>
                    <label className="kontakt__label">
                        <p className="small bold wordspacing">IHRE EMAIL-ADRESSE*</p>
                        <input type="email" name="email" id="email" className="kontakt__inputfield" placeholder="Name@Domain.xx" value={value[`email`] || ``} onChange={handleChange}/>
                    </label>
                    <p className="small bold serverresponse" ref={serverResponse}></p>
                    <div className="kontakt__btn__container">
                        <button type="submit" className="btn smalltext bold outline black large mobile-large color-black" value="BERECHNEN">
                            BERECHNEN
                        </button>
                        <p className="small bold">*Pflichtfelder</p>
                    </div>
                </form>
            </div>
        </div>
    )
}
export default Energierechner
