import React from 'react';
import { Helmet } from 'react-helmet';

const Head = ({ title}) => {
    return (
        <>
            <Helmet
                defaultTitle="eniosol"
                title={title}
                titleTemplate="%s | eniosol"
            />
        </>
    )
}

export default Head;
